<template>
<div class="sub_cont re_service bg_gray">
   <div class="box_top_bg">
      <div class="sub_title_rs">
        서비스 신청
      </div>
      <div class="sub_title_rs2">
      {{ !!loginUserData ? loginUserData.cmpyNm+', '+loginUserData.userNm+'님' : '' }} 안녕하세요. <br />
      EFS SaaS를 통해 새로운 물류서비스를 이용해보세요.
      </div>
      <div class="box_tep">
        <ul>
          <li :class="stepIndex=== 0 ? 'on' : ''">
            <div class="txt_step">step 01.</div>
            <div>물류 운영 상품 정보</div>
          </li>
          <li :class="stepIndex=== 1 ? 'on' : ''">
            <div class="txt_step">step 02.</div>
            <div>서비스 등급</div>
          </li>
        </ul>
      </div>
    </div>
    <keep-alive>
      <div v-if="stepIndex===0" class="cont">
        <h2 class="mgt4">물류 운영 형태</h2>
        <ol class="btn_round">
          <li v-for="item in $store.state.codeRequestStore.search.combobox.logiType.data"
              :class="logiType === item.cdId ? 'on' : ''"
              @click="logiType = item.cdId">{{item.cdNm}}</li>
        </ol>

         <h2 v-if="logiType === constant.reverse" >역물류 프로세스</h2>
        <ol v-if="logiType === constant.reverse" class="res_pro">
          <li>
            <div class="txt_num">01</div>
            <img src="../../assets/img/res001.png"/>
            <div class="txt_pro_title">신규상품입고</div>
          </li>
          <li>
            <div class="txt_num">02</div>
            <img src="../../assets/img/res002.png"/>
            <div class="txt_pro_title">상품주문 연동|생성</div>
          </li>
          <li>
            <div class="txt_num">03</div>
            <img src="../../assets/img/res003.png"/>
            <div class="txt_pro_title">상품 출고</div>
          </li>
          <li>
            <div class="txt_num">04</div>
            <img src="../../assets/img/res004.png"/>
            <div class="txt_pro_title">상품 배송</div>
          </li>
          <li>
            <div class="txt_num">05</div>
            <img src="../../assets/img/res005.png"/>
            <div class="txt_pro_title">고객 수신</div>
          </li>
          <li>
            <div class="txt_num">06</div>
            <img src="../../assets/img/res006.png"/>
            <div class="txt_pro_title">고객 VOC</div>
          </li>
          <li>
            <div class="txt_num">07</div>
            <img src="../../assets/img/res007.png"/>
            <div class="txt_pro_title">회수품 재생</div>
          </li>
          <li>
            <div class="txt_num">08</div>
            <img src="../../assets/img/res008.png"/>
            <div class="txt_pro_title">회수품 검품</div>
          </li>
          <li>
            <div class="txt_num">09</div>
            <img src="../../assets/img/res009.png"/>
            <div class="txt_pro_title">상품 회수</div>
          </li>
          <li>
            <div class="txt_num">10</div>
            <img src="../../assets/img/res010.png"/>
            <div class="txt_pro_title">고객 VOC</div>
          </li>
        </ol>

        <!--일반물류-->
        <h2 v-if="logiType === constant.general">일반물류 프로세스</h2>
        <ol v-if="logiType === constant.general" class="res_pro nomal">
         <li>
            <div class="txt_num">01</div>
            <img src="../../assets/img/res001.png"/>
            <div class="txt_pro_title">신규상품입고</div>
          </li>
          <li>
            <div class="txt_num">02</div>
            <img src="../../assets/img/res002.png"/>
            <div class="txt_pro_title">상품주문 연동|생성</div>
          </li>
          <li>
            <div class="txt_num">03</div>
            <img src="../../assets/img/res003.png"/>
            <div class="txt_pro_title">상품 출고</div>
          </li>
          <li>
            <div class="txt_num">04</div>
            <img src="../../assets/img/res004.png"/>
            <div class="txt_pro_title">상품 배송</div>
          </li>
          <li>
            <div class="txt_num">05</div>
            <img src="../../assets/img/res005.png"/>
            <div class="txt_pro_title">고객 수신</div>
          </li>
          <li>
            <div class="txt_num">06</div>
            <img src="../../assets/img/res006.png"/>
            <div class="txt_pro_title">고객 VOC</div>
          </li>
        </ol>
        <ol class="res_list">
            <li>
              <h2>주요 운영 상품</h2>
              <input ref="mainGoodsTextRef" type="text" v-model="serviceReq.mainGoodsText" name="textfield2" id="textfield2" placeholder="화장품, PC, 골프용품 등 ">
            </li>
            <li>
              <h2>상품종류(종)</h2>
              <input ref="goodsTypeCntRef" type="text" v-model="serviceReq.goodsTypeCnt" name="textfield2" id="textfield2" placeholder="10 ">
            </li>
            <li>
              <h2>상품수량(개)</h2>
              <input ref="goodsCntRef" type="text" v-model="serviceReq.goodsCnt" name="textfield2" id="textfield2" placeholder="1,000 ">
            </li>
            <li>
              <h2>월 예상 출고 건수*</h2>
              <select v-model="serviceReq.expectMonthOutCnt"><input type="text" name="textfield2" id="textfield2">
                <option v-for="item in $store.state.codeRequestStore.search.combobox.expectMonthOutCnt.data"
                        :value="item.cdId"
                >
                  {{ item.cdNm }}
                </option>
              </select>
            </li>
        </ol>
        <div class="box_btn">
          <div class="btn orange" @click="onClickNextStep">다음</div>
        </div>
      </div>
    </keep-alive>
  <!--서비스 등급-->
    <div v-show="stepIndex===1" class="cont service_class">
      <h2 class="mgt4"><span class="fc_b">서비스 등급</span>을 선택해주세요.</h2>
      <div class="box_service_select">
        <div v-for="(item, i) in commonSvcMainListResponseList" :class="selectMainSvcNo === item.svcNo ? 'on wow fadeInUp' : 'wow fadeInUp'"
             @click="e=>{
               selectMainSvcNo = item.svcNo
               selectMainSvcItem = item
             }"
             :data-wow-duration="i+1+'s'"
             :data-wow-delay="`0.${i+2}s`">
          <div class="box_service">
            <div class="top_title">{{ item.svcGradeNm }}</div>
            <dl class="pc">
              <span v-for="childItem in item.commonSvcDtlResponseList">
                <dt>{{ childItem.svcDtlNm }}<span class="txt_s">[{{ childItem.svcDtlBillCdNm }}]</span></dt>
                <dd>{{ childItem.svcDtlBillCost | comma }}원</dd>
              </span>
            </dl>
            <ul class="mobile">
              <li v-for="childItem in item.commonSvcDtlResponseList">
                <dl>
                  <dt>{{ childItem.svcDtlNm }}<span class="txt_s">[{{ childItem.svcDtlBillCdNm }}]</span></dt>
                  <dd>{{ childItem.svcDtlBillCost | comma}}원</dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="box_check_rs_btn">
        <h2>추가 기능을 선택해주세요.[다중선택 가능]</h2>
        <div v-for="(item, i) in commonSvcAddListResponseList"
             :class="selectAddSvcNo.includes(item.svcNo) ? 'btn_check on':'btn_check'"
             @click="onClickAddSvc(item)"
        >
          <template v-for="childItem in item.commonSvcDtlResponseList">
            <img :src="item.img">
            <div class="service_name">{{ item.customName }}</div>
            <div class="price_r">{{childItem.svcDtlBillCdNm}}
              <span class="fc_b">{{ childItem.svcDtlBillCost | comma}}</span>원
            </div>
          </template>
        </div>
      </div>
      <div class="charge">
        <h2 class="mgt4">예상 이용료</h2>
        <div class="txt">월 <span class="txt_b_num">{{ exampleOutCnt | comma}}</span>개를 발송했을때, <br/>
        예상되는 <b>월 이용료</b>는  <span class="txt_b_num total">{{ exampleCost | comma }}</span> 원 입니다.
        </div>
        <div class="info_txt">※ 해당요금은 시뮬레이션 결과이며 실제 비용과 상이할 수 있습니다.</div>
      </div>
      <h2 class="essential">신청자 휴대전화 인증</h2>
      <div class="box_flex">
       <div>
         <input ref="spTelNoRef"
                type="text"
                :disabled="isPassSms"
                v-model="serviceReq.spTelNo"
                @input="() => {serviceReq.spTelNo = serviceReq.spTelNo.replace(/[^0-9]/g, '')}"
                placeholder=" - 제외한 11자리">
       </div>
       <button class="smbtn gray"
            :disabled="isPassSms"
            @click="sendAuth">인증번호 발송</button>
       <div>
         <input ref="authNumberRef"
                type="text"
                v-model.trim="authNumber"
                :disabled="isPassSms"
                placeholder="인증번호 4자리"
                @input="() => {authNumber = authNumber.replace(/[^0-9]/g, '')}"
                :maxlength="4">
         <div>
           <span v-show="isCheckSms && isPassSms" class="msg_green">유효한 인증번호입니다.</span>
           <span v-show="isCheckSms && !isPassSms" class="msg_red">인증번호가 일치하지 않습니다.</span>
           <span v-show="!isPassSms" id="leftTime"></span>
         </div>
       </div>
       <button class="smbtn gray"
            @click="onSubmit"
            :disabled="isPassSms">인증</button>
      </div>
      <div class="box_btn">
        <div class="btn" @click="stepIndex = 0">이전</div>
        <div class="btn orange" @click="onClickSvcReq">서비스 신청</div>
      </div>
    </div>
  <popup-for-landing
      ref="popupRef">
  </popup-for-landing>
</div>
</template>

<script>

import { WOW } from 'wowjs'
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import PopupForLanding from '@/landing/components/PopupForLanding'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util'

export default {
  name: 'RequestService',
  components: { PopupForLanding },
  watch :{
    stepIndex : function () {
      new WOW().init();
    },
  },
  computed : {
    loginUserData: function () {
      return this.$store.getters['loginUserData']
    },
    exampleCost :  function (){
      const vm = this
      // 기본료 + ( 입고검수 + 출고/회수 + 태블릿리퍼 ) * 앞의 월 예상 출고건수 + 추가기능
      let cost = 0
      let tempMonthCost = 0
      vm.selectMainSvcItem?.commonSvcDtlResponseList?.forEach(item=>{
        if(item.svcDtlCd === '0001'){
          // 기본료
          cost += item.svcDtlBillCost
        }else{
          // ( 입고검수 + 출고/회수 + 태블릿리퍼 )
          tempMonthCost += item.svcDtlBillCost
        }
      })
      // ( 입고검수 + 출고/회수 + 태블릿리퍼 ) * 앞의 월 예상 출고건수
      cost += (tempMonthCost * vm.exampleOutCnt)

      // 추가기능
      vm.commonSvcAddListResponseList.forEach((item, i)=>{
        if(vm.selectAddSvcNo.includes(item.svcNo)){
          item.commonSvcDtlResponseList.forEach(childItem=>{
            cost += childItem.svcDtlBillCost
          })
        }
      })

      return cost
    },
    exampleOutCnt : function (){
      const vm = this
      let outCnt = vm.$store.state.codeRequestStore.search.combobox.expectMonthOutCnt.data
      .find(value=>value.cdId === vm.serviceReq.expectMonthOutCnt)?.cdDesc1
      return Number(outCnt) ?? 0
    }
  },
  created () {
    const vm = this
    vm.getSvcList()
  },
  mounted() {
    new WOW().init();
  },
  methods : {
    onClickSvcReq : function (){
      const vm = this
      if(svcReqValidator()){
        vm.saveSvcReq()
      }
      function svcReqValidator(){
        if(!vm.serviceReq.spTelNo) {
          vm.$refs.popupRef.alert('휴대전화번호를 입력해 주세요.', function() {
            vm.$refs.spTelNoRef.focus()
          })
          return false
        }
        if(!vm.isPassSms){
          vm.$refs.popupRef.alert('휴대전화 인증을 완료해주세요', function() {
            vm.$refs.spTelNoRef.focus()
          })
          return false
        }
        return true
      }
    },
    saveSvcReq : function (){
      const vm = this
      let param = {
        ...vm.serviceReq,
        cmpyCd : vm.loginUserData.cmpyCd,
        logiType : vm.logiType,
        certiSpTelNo : vm.serviceReq.spTelNo,
        certiYn : vm.isPassSms ? 'Y' : 'N',
        commonSvcNoRequestParamList : [],
      }
      param.commonSvcNoRequestParamList.push({ svcNo : vm.selectMainSvcNo})
      vm.selectAddSvcNo.forEach(svcNo=>{
        param.commonSvcNoRequestParamList.push({ svcNo : svcNo })
      })

      ApiUtil.post(`${ApiConfig.efsDomain}/efs/svc`, param).then(response => {
        if (!response.data?.resultStatus) {
          vm.$refs.popupRef.alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          vm.$refs.popupRef.alert(response.data.resultStatus.messageText)
          return false
        }
        vm.$router.push({ name: 'ApplicationCompleted' })
      })
    },
    sendAuth () {
      const vm = this

      if(!vm.serviceReq.spTelNo?.trim()) {
        vm.$refs.popupRef.alert('휴대전화번호를 입력해 주세요.', function() { vm.$refs.spTelNoRef.focus() })
        return false
      }
      if(vm.serviceReq.spTelNo.length < 11) {
        vm.$refs.popupRef.alert('휴대전화번호 11자리를 입력해주세요.', function() { vm.$refs.spTelNoRef.focus() })
        return false
      }

      ApiUtil.post(`${ApiConfig.efsDomain}/efs/user/send-auth-number`, { userId : vm.loginUserData.userId, spTelNo : vm.serviceReq.spTelNo, transferMethod : 'phone'  }).then(response => {

        if(response.data.resultStatus.messageCode !== '2000') {
          vm.$refs.popupRef.alert('알 수 없는 오류가 발생하였습니다. 관리자에게 문의해주세요.')
          return false
        }

        vm.$refs.popupRef.alert('인증번호가 전송되었습니다. 유효시간은 2분 30초입니다.')

        // 남은 시간
        let leftSec = 150,
            display = document.querySelector('#leftTime')
        // 이미 타이머가 작동중이면 중지
        if (this.isTimerRunning) {
          clearInterval(this.leftTimer)
        }
        // 인증 유효시간 출력
        this.showLeftTime(leftSec, display)
        // data setting
        this.authNumberComfirmBoolean = false
        this.authNumber = null
        // this.authNumberServer = response.data.resultData.authNo
        this.authNumberComfirmMsg = '<span class="msg_green"></span>'
      })
    },
    showLeftTime (count, display) {
      const vm = this
      let minutes, seconds
      this.isTimerRunning = true
      this.leftTimer = setInterval(function () {
        minutes = parseInt(count / 60, 10)
        seconds = parseInt(count % 60, 10)
        minutes = minutes < 10 ? '0' + minutes : minutes
        seconds = seconds < 10 ? '0' + seconds : seconds

        if (!vm.authNumberComfirmBoolean) {
          display.textContent = '\xa0\xa0\xa0' + minutes + ':' + seconds
          if (--count < 0) {
            clearInterval(vm.leftTimer)
            display.textContent = '시간초과'
            display.style.color = '#ff0000'
            this.isTimerRunning = false
            // this.authNumberServer = null
            return false
          }
        } else {
          clearInterval(vm.leftTimer)
          display.textContent = ''
          this.isTimerRunning = false
          return false
        }
      }, 1000)
    },
    // 인증 확인
    onSubmit () {
      const vm = this

      if(!vm.authNumber) {
        vm.$refs.popupRef.alert('인증번호 4자리를 입력해 주세요', function() {
          vm.$refs.authNumberRef.focus()
        })
        return false
      }

      ApiUtil.query(`${ApiConfig.efsDomain}/efs/user/confirm-auth-number`,
          { userId : vm.loginUserData.userId, authNo : vm.authNumber }).then(response => {

        if(response.data.resultStatus.messageCode === '2000') {
          vm.isPassSms = true
          vm.isCheckSms = true
        }else {
          vm.$refs.popupRef.alert(response.data.resultStatus.messageText)
          return false
        }
      })
    },
    onClickAddSvc : function (item){
      const vm = this
      let existIndex = vm.selectAddSvcNo.findIndex(value=> value === item.svcNo)
      if(existIndex === -1){
        vm.selectAddSvcNo.push(item.svcNo)
      }else {
        vm.selectAddSvcNo.splice(existIndex, 1)
      }
    },
    onClickNextStep : function (){
      const vm = this
      if(validator()){
        vm.stepIndex = 1
      }
      function validator(){
        if(!vm.serviceReq.mainGoodsText){
          vm.$refs.popupRef.alert('주요 운영 상품을 입력해주세요.', function (){
            vm.$refs.mainGoodsTextRef.focus()
          })
          return false
        }
        if(!vm.serviceReq.goodsTypeCnt){
          vm.$refs.popupRef.alert('상품종류의 수를 입력해주세요.', function (){
            vm.$refs.goodsTypeCntRef.focus()
          })
          return false
        }
        if(!vm.serviceReq.goodsCnt){
          vm.$refs.popupRef.alert('상품 수량을 입력해주세요.', function (){
            vm.$refs.goodsCntRef.focus()
          })
          return false
        }
        return true
      }
    },
    goLogin : function (){
      window.location.href = '/login'
    },
    getSvcList : function() {
      const vm = this
      ApiUtil.query(`${ApiConfig.efsDomain}/efs/svc/pricing`)
      .then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          alert('데이터가 정상적으로 처리되지 않았습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          alert(response.data.resultStatus.messageText)
          return false
        }
        vm.commonSvcAddListResponseList = response.data.resultData.commonSvcAddListResponseList
        vm.commonSvcAddListResponseList.forEach((item, i)=>{
          switch (item.svcNo){
            case vm.constant.svcNo.sub_3D : {
              item.customName = '3D VIEW 적용'
              item.img = require('../../assets/img/p004.png')
              item.select = false
              break
            }
            case vm.constant.svcNo.sub_MEDIA : {
              item.customName = '출고/회수 미디어 저장'
              item.img = require('../../assets/img/p005.png')
              item.select = false
              break
            }
          }
        })
        vm.commonSvcMainListResponseList = response.data.resultData.commonSvcMainListResponseList
        if(vm.commonSvcMainListResponseList.length > 0){
          vm.selectMainSvcNo = vm.commonSvcMainListResponseList[0].svcNo
          vm.selectMainSvcItem = vm.commonSvcMainListResponseList[0]
        }
      })
    }
  },
  data () {
    return {
      stepIndex : 0,
      serviceReq : {
        expectMonthOutCnt : '01',
      },
      selectMainSvcNo : '',
      selectMainSvcItem : {},
      selectAddSvcNo : [],
      commonSvcAddListResponseList : [],
      commonSvcMainListResponseList : [],
      existLoginInfo : true,
      logiType : '01',
      isCheckSms : false,
      isPassSms : false,
      authNumberComfirmBoolean : false,
      authNumber : '',
      authNumberServer : null,
      authNumberComfirmMsg: '<span class="msg_green"></span>',
      leftTimer: null,
      constant : {
        reverse : '01',
        general : '02',
        svcNo : {
          sub_MEDIA : "202208010004",
          sub_3D : "202208010005"
        }
      }
    }
  },
}
</script>
<style scoped>

</style>
